/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable array-callback-return */
import * as diacritics from "diacritics"
import { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import internationaPlaces from "../../../core/constants/frequentPlaces/international.json"
import nationalPlaces from "../../../core/constants/frequentPlaces/national.json"
import nationalOriginsPlaces from "../../../core/constants/frequentPlaces/nationalOrigins.json"
import topFrequentPlaces from "../../../core/constants/frequentPlaces/topFrequentPlaces.json"
import { frequentPlacesPros } from "../../../core/constants/frequentPlaces/types"
import AdBanner from "../../../core/helpers/Ads"
import {
  calculateDateDifference,
  convertArrayStringToObjects,
  formatDatesWithLeadingZeros,
  getCurrentTime,
  sortDatesArray,
  transformDatesToGTM,
} from "../../../core/helpers/DateTime"
import { formatStringToSlug } from "../../../core/helpers/String"
import ViewportChecker from "../../../core/helpers/ViewPort"
import {
  getIsOneWayTrip,
  getNumberOfPassengers,
  getSearchIntent,
  setIsOnewayTrip,
  setNumberOfPassengers,
} from "../../../store/flights"
import Button from "../../Buttons/Button"
import CountButton from "../../Buttons/CountButton"
import PassengersSelector from "../../Buttons/PassengersSelector"
import RadioGroup from "../../Buttons/RadioGroup"
import TripTypeSwitch from "../../Buttons/TripTypeSwitch"
import FloatContainer from "../../Containers/FloatContainer"
import FooterModal from "../../Containers/FooterModal"
import CalendarPicker from "../../DataEntry/CalendarPicker"
import { CalendarDateProps } from "../../DataEntry/CalendarPicker/types"
import Switch from "../../DataEntry/Switch"
import Icon from "../../Icon"
import Toast from "../../StatusDisplay/Toast"
import InputSearch from "./InputSearch"
import airportsData from "./InputSearch/airports.json"
import { airportPros } from "./InputSearch/types"
import "./SearchFlights.css"
import altRedirect from "../../../services/alt-redirect.json"
import { getEnvironment } from "../../../core/helpers/Environments"

function SearchFlights() {
  const dispatch = useDispatch()

  const [isDepartureDateSelected, handlerDepartureDateSelected] =
    useState<boolean>(true)
  const [isCalendarVisible, setCalendarVisible] = useState<boolean>(false)

  const [departureDateSelected, setDepartureDateSelected] = useState<
    CalendarDateProps[] | []
  >([])
  const [departureDateSelectedSaved, setDepartureDateSelectedSaved] = useState<
    CalendarDateProps[] | []
  >([])
  const [returnDateSelected, setReturnDateSelected] = useState<
    CalendarDateProps[] | []
  >([])
  const [returnDateSelectedSaved, setReturnDateSelectedSaved] = useState<
    CalendarDateProps[] | []
  >([])

  const [isOrigin, setIsOrigin] = useState<boolean>(true)

  const [originAirport, setOriginAirport] = useState<string | null>(null)
  const [originLabel, setOriginLabel] = useState<string>("")
  const [destinationLabel, setDestinationLabel] = useState<string>("")
  const [destinationAirport, setDestinationAirport] = useState<string | null>(
    null
  )

  const [originCity, setOriginCity] = useState<string>("")
  const [destinationCity, setDestinationCity] = useState<string>("")

  const [departureDatesList, setDepartureDatesList] = useState<string[] | []>(
    []
  )
  const [returnDatesList, setReturnDatesList] = useState<string[] | []>([])
  const [isOriginAirportError, handlerOriginAirportError] =
    useState<boolean>(false)
  const [isDestinationAirportError, handlerDestinationAirportError] =
    useState<boolean>(false)
  const [isDepartureDateError, handlerDepartureDateError] =
    useState<boolean>(false)

  const [isReturnDateError, handlerReturnDateError] = useState<boolean>(false)

  const [screenWidth, setScreenWidth] = useState(window.innerWidth)
  const [isShowSearchModal, handlerSearchModal] = useState(false)
  const [isShowPassengersSelectorModal, handlerShowPassengersSelectorModal] =
    useState(false)

  const [isShowPassengersSelectorWeb, handlerShowPassengersSelectorWeb] =
    useState(false)

  const [isShowFrequentPlacesWeb, handlerShowFrequentPlacesWeb] =
    useState(false)

  const searchItent = useSelector(getSearchIntent)
  const isOneWayTrip = useSelector(getIsOneWayTrip)
  const numberOfPassengers = useSelector(getNumberOfPassengers)

  const [amountOfAdults, setAmountOfAdults] = useState(
    numberOfPassengers.adults
  )
  const [amountOfChildren, setAmountOfChildren] = useState(
    numberOfPassengers.children
  )
  const [amountOfBabies, setAmountOfBabies] = useState(
    numberOfPassengers.babies
  )

  const [isBlurFrequentPlacesList, handlerBlurFrequentPlacesList] =
    useState(false)

  const [destinationInputWidth, setDestinationInputWidth] = useState(0)

  const [isShowToast, handlerShowToast] = useState(false)
  const [toastMessage, setToastMessage] = useState("")
  const [toastKey, setToastKey] = useState("")
  const [isHoveredDivPassengers, setIsHoveredDivPassengers] =
    useState<boolean>(false)

  const isMobile = ViewportChecker()

  useEffect(() => {
    if (
      (isCalendarVisible ||
        isShowSearchModal ||
        isShowPassengersSelectorModal) &&
      isMobile
    ) {
      document.body.style.overflowY = "hidden"
      document.body.style.overflowX = "hidden"
    } else {
      document.body.style.overflowY = "auto"
      document.body.style.overflowX = "hidden"
    }
  }, [isCalendarVisible, isShowSearchModal, isShowPassengersSelectorModal])

  useEffect(() => {
    const handleResize = () => {
      setScreenWidth(window.innerWidth)
    }

    window.addEventListener("resize", handleResize)

    return () => window.removeEventListener("resize", handleResize)
  }, [])

  useEffect(() => {
    setAmountOfAdults(numberOfPassengers.adults)
    setAmountOfChildren(numberOfPassengers.children)
    setAmountOfBabies(numberOfPassengers.babies)
  }, [numberOfPassengers])

  useEffect(() => {
    const dateList: string[] = []

    if (isDepartureDateSelected) {
      departureDateSelectedSaved.map((item) => {
        dateList.push(`${item.year}-${item.month}-${item.day}`)
      })

      setDepartureDatesList(formatDatesWithLeadingZeros(dateList))
    } else {
      returnDateSelectedSaved.map((item) => {
        dateList.push(`${item.year}-${item.month}-${item.day}`)
      })

      setReturnDatesList(formatDatesWithLeadingZeros(dateList))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [departureDateSelectedSaved, returnDateSelectedSaved])

  useEffect(() => {
    if (
      searchItent?.departureDate &&
      searchItent?.originAirport &&
      searchItent?.destinationAirport &&
      searchItent?.quantityOfAdults
      // searchItent?.quantityOfChildrens &&
      // searchItent?.quantityOfBabies
    ) {
      // console.log('departureDate = ', searchItent?.departureDate);
      window.dataLayer.push(setFlightSearchGTM())
    }
  }, [searchItent])

  useEffect(() => {
    if (searchItent?.departureDate?.length) {
      setDepartureDatesList(searchItent?.departureDate)

      setDepartureDateSelected(
        convertArrayStringToObjects(searchItent?.departureDate)
      )

      setDepartureDateSelectedSaved(
        convertArrayStringToObjects(searchItent?.departureDate)
      )
    }

    if (searchItent?.returnDate?.length) {
      const returnDatesObjectList = convertArrayStringToObjects(
        searchItent?.returnDate
      )

      setReturnDatesList(searchItent?.returnDate)

      setReturnDateSelected(returnDatesObjectList)
      setReturnDateSelectedSaved(returnDatesObjectList)
    }
  }, [searchItent])

  const onSelecterCalendar = (isDepartureDateCalendar: boolean) => {
    handlerDepartureDateSelected(isDepartureDateCalendar)

    setDepartureDateSelected(departureDateSelectedSaved)
    setReturnDateSelected(returnDateSelectedSaved)

    const timer = setTimeout(() => {
      setCalendarVisible(!isCalendarVisible)
    }, 100)

    return () => clearTimeout(timer)
  }

  function setCookie(name: string, value: string, days: number) {
    const date = new Date()
    date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000) // Calcula o tempo em milissegundos
    const expires = "expires=" + date.toUTCString() // Define a data de expiração no formato correto
    document.cookie = `${name}=${value}; ${expires}; path=/` // Cria o cookie
  }

  function getCookie(name: string) {
    const cookies = document.cookie.split(";") // Divide os cookies em um array
    const prefix = `${name}=` // Prefixo do cookie a ser procurado

    for (let i = 0; i < cookies.length; i++) {
      let cookie = cookies[i].trim() // Remove espaços em branco no início e no final
      if (cookie.startsWith(prefix)) {
        // Verifica se o cookie começa com o nome procurado
        return cookie.substring(prefix.length) // Retorna o valor do cookie
      }
    }

    return null // Retorna null se o cookie não for encontrado
  }

  const environmentType = getEnvironment()

  const handleAltRedirectGTMEvent = (
    originAirport: string,
    destinationAirport: string,
    departureDates: string,
    numberOfAdults: number,
    returnDates?: string,
    numberOfChildren?: number,
    numberOfBabies?: number
  ) => {
    try {
      const event = {
        event: `alt_redirect-${environmentType}`,
        anunciante: isMobile
          ? altRedirect.ALT_REDIRECT_MOBILE_CURRENT_CAMPAIGN_NAME
          : altRedirect.ALT_REDIRECT_DESKTOP_CURRENT_CAMPAIGN_NAME,
        IATA_origin: originAirport,
        IATA_destination: destinationAirport,
        IATA_route: `${originAirport}-${destinationAirport}`,
        departure_date: departureDates,
        return_date: returnDates ?? "",
        numberOfAdults: numberOfAdults,
        numberOfChildren: numberOfChildren ?? 0,
        numberOfBabies: numberOfBabies ?? 0,
      }

      console.log(event)
      window?.dataLayer?.push(event)
      console.log("enviado")
    } catch (error) {
      console.error("Erro ao executar handleAltRedirectGTMEvent", error)
    }
  }

  const handleAltRedirect = (
    originAirport: string,
    destinationAirport: string
  ) => {
    try {
      //Se o usuário já viu uma vez este redirect, não verá mais por 7 dias ou até apagar os cookies
      if (
        getCookie(altRedirect.ALT_REDIRECT_COOKIE_NAME ?? "alt-redirect") !==
        null
      ) {
        return { execute: false }
      }

      let altRedirectUrl = ""
      let altRedirectAirports: any = null

      if (
        isMobile &&
        altRedirect.ALT_REDIRECT_MOBILE_ACTIVE !== undefined &&
        altRedirect.ALT_REDIRECT_MOBILE_ACTIVE === "true"
      ) {
        if (
          altRedirect.ALT_REDIRECT_MOBILE_URL !== undefined &&
          altRedirect.ALT_REDIRECT_MOBILE_URL !== "" &&
          altRedirect.ALT_REDIRECT_MOBILE_URL.indexOf("http") > -1
        ) {
          altRedirectUrl = altRedirect.ALT_REDIRECT_MOBILE_URL
        }

        if (
          altRedirect.ALT_REDIRECT_MOBILE_AIRPORTS !== undefined &&
          altRedirect.ALT_REDIRECT_MOBILE_AIRPORTS !== ""
        ) {
          altRedirectAirports =
            altRedirect.ALT_REDIRECT_MOBILE_AIRPORTS.indexOf(",")
              ? altRedirect.ALT_REDIRECT_MOBILE_AIRPORTS.split(",")
              : altRedirect.ALT_REDIRECT_MOBILE_AIRPORTS
        }
      }

      //Configuração de se está ativo ou não
      if (
        !isMobile &&
        altRedirect.ALT_REDIRECT_DESKTOP_ACTIVE !== undefined &&
        altRedirect.ALT_REDIRECT_DESKTOP_ACTIVE === "true"
      ) {
        //Configuração de URL de Redirect
        if (
          altRedirect.ALT_REDIRECT_DESKTOP_URL !== undefined &&
          altRedirect.ALT_REDIRECT_DESKTOP_URL !== "" &&
          altRedirect.ALT_REDIRECT_DESKTOP_URL.indexOf("http") > -1
        ) {
          altRedirectUrl = altRedirect.ALT_REDIRECT_DESKTOP_URL
        }

        //Configuração de Aeroportos
        if (
          altRedirect.ALT_REDIRECT_DESKTOP_AIRPORTS !== undefined &&
          altRedirect.ALT_REDIRECT_DESKTOP_AIRPORTS !== ""
        ) {
          altRedirectAirports =
            altRedirect.ALT_REDIRECT_DESKTOP_AIRPORTS.indexOf(",")
              ? altRedirect.ALT_REDIRECT_DESKTOP_AIRPORTS.split(",")
              : altRedirect.ALT_REDIRECT_DESKTOP_AIRPORTS
        }
      }

      if (altRedirectUrl !== "") {
        let execute = false

        console.log(altRedirectAirports)

        //Se tem algum critério de Aeroportos
        if (altRedirectAirports != null) {
          if (
            altRedirectAirports.indexOf(originAirport) > -1 ||
            altRedirectAirports.indexOf(destinationAirport) > -1
          ) {
            execute = true
          }
        } else {
          execute = true
        }

        if (execute) {
          setCookie(
            altRedirect.ALT_REDIRECT_COOKIE_NAME ?? "alt-redirect",
            "true",
            7
          )
        }

        return { execute: execute, url: altRedirectUrl }
      } else {
        if (isMobile) {
          console.info(
            `Alt-Redirect não configurado - ACTIVE: ${altRedirect.ALT_REDIRECT_MOBILE_ACTIVE} - URL: ${altRedirect.ALT_REDIRECT_MOBILE_URL} - AIRPORTS: ${altRedirect.ALT_REDIRECT_MOBILE_AIRPORTS}`
          )
        }

        if (!isMobile) {
          console.info(
            `Alt-Redirect não configurado - ACTIVE: ${altRedirect.ALT_REDIRECT_DESKTOP_ACTIVE} - URL: ${altRedirect.ALT_REDIRECT_DESKTOP_URL} - AIRPORTS: ${altRedirect.ALT_REDIRECT_DESKTOP_AIRPORTS}`
          )
        }

        return { execute: false }
      }
    } catch (error) {
      console.log("Erro em handleAltRedirect", error)
      return { execute: false }
    }
  }

  const onSubmitFlightsSearch = () => {
    let serpUrl = ""
    const baseUrl = window.location.origin

    const newDepartureDatesList: string[] = []
    const newReturnDatesList: string[] = []

    if (!originAirport) {
      handlerShowToast(true)
      setToastMessage("Uma origem deve ser selecionada!")
      setToastKey("origin-airport-error")
      return
    } else if (!destinationAirport) {
      handlerShowToast(true)
      setToastMessage("Um destino deve ser selecionado!")
      setToastKey("destination-airport-error")
      return
    } else if (departureDatesList.length <= 0) {
      handlerShowToast(true)
      setToastMessage("Selecione pelo menos uma data de ida!")
      setToastKey("departure-dates-error")
      return
    } else if (
      !isOneWayTrip &&
      departureDatesList.length > 0 &&
      returnDatesList.length <= 0
    ) {
      handlerShowToast(true)
      setToastMessage("Selecione pelo menos uma data de volta!")
      setToastKey("departure-dates-error")
      return
    } else if (originAirport === destinationAirport) {
      // } else if (originAirport === 'test') {
      handlerShowToast(true)
      setToastMessage("A origem e destino não podem ser iguais!")
      setToastKey("departure-dates-error")
      return
    } else {
      departureDateSelectedSaved.map((item) => {
        newDepartureDatesList.push(`${item.year}-${item.month + 1}-${item.day}`)
      })

      returnDateSelectedSaved.map((item) => {
        newReturnDatesList.push(`${item.year}-${item.month + 1}-${item.day}`)
      })

      if (isOneWayTrip) {
        if (originAirport && destinationAirport && departureDatesList.length) {
          // window.dataLayer.push(setFlightSearchGTM());

          serpUrl = `${baseUrl}/passagens-aereas-de-${originCity}/${originAirport}/para-${destinationCity}/${destinationAirport}?origin-airport=${originAirport}&destination-airport=${destinationAirport}&departure-date=${formatDatesWithLeadingZeros(
            newDepartureDatesList
          )}&return-date=&quantity-of-adults=${
            numberOfPassengers.adults
          }&quantity-of-children=${
            numberOfPassengers.children
          }&quantity-of-babies=${
            numberOfPassengers.babies
          }&total-expected-requests=6`
        }
      } else {
        if (
          originAirport &&
          destinationAirport &&
          departureDatesList.length &&
          returnDatesList.length
        ) {
          serpUrl = `${baseUrl}/passagens-aereas-de-${originCity}/${originAirport}/para-${destinationCity}/${destinationAirport}?origin-airport=${originAirport}&destination-airport=${destinationAirport}&departure-date=${formatDatesWithLeadingZeros(
            newDepartureDatesList
          )}&return-date=${formatDatesWithLeadingZeros(
            newReturnDatesList
          )}&quantity-of-adults=${
            numberOfPassengers.adults
          }&quantity-of-children=${
            numberOfPassengers.children
          }&quantity-of-babies=${
            numberOfPassengers.babies
          }&total-expected-requests=6`
        }
      }
    }

    //Busca se existe Alt Redirect configurado
    const altRedirect = handleAltRedirect(destinationAirport, originAirport)

    //Se tem Alt Redirect, primeiro abre a SERP numa nova aba + muda a aba atual de URL
    if (altRedirect.execute) {
      window.open(serpUrl, "_blank")

      //Garante que a URL de redirect Alt Redirect funciona
      if (altRedirect.url) {
        window.location.href = altRedirect.url
      }

      //Envia evento GTM
      try {
        handleAltRedirectGTMEvent(
          destinationAirport,
          originAirport,
          formatDatesWithLeadingZeros(newDepartureDatesList).join(","),
          numberOfPassengers.adults,
          formatDatesWithLeadingZeros(newReturnDatesList)?.join(",") ?? "",
          numberOfPassengers.children,
          numberOfPassengers.babies
        )
      } catch (error) {
        console.error("Erro ao chamar handleAltRedirectGTMEvent", error)
      }
    } else {
      window.location.href = serpUrl
    }
  }

  const setFlightSearchGTM = () => {
    const countryOrigin = airportsData.filter(
      (item) => item.airport_code === searchItent?.originAirport
    )[0]?.country_code

    const countryDestination = airportsData.filter(
      (item) => item.airport_code === searchItent?.destinationAirport
    )[0]?.country_code

    const destinationAutocomplete = airportsData.filter(
      (item) => item.airport_code === searchItent?.destinationAirport
    )[0].city_name

    const originAutocomplete = airportsData.filter(
      (item) => item.airport_code === searchItent?.originAirport
    )[0].city_name

    const event = {
      event: "flightSearch-staging",
      sale_userid: null,
      facebook_userid: "",
      sale_source: null,
      sale_subsource: null,
      IATA_origin: searchItent?.originAirport,
      IATA_destination: searchItent?.destinationAirport,
      IATA_route: `${searchItent?.originAirport}-${searchItent?.destinationAirport}`,
      adults: `${numberOfPassengers.adults}`,
      children: `${numberOfPassengers.children}`,
      babies: `${numberOfPassengers.babies}`,
      searchID: `ao=${searchItent?.originAirport}|ad=${
        searchItent?.destinationAirport
      }|dl1=${searchItent?.departureDate[0]}|dl2=${
        searchItent?.departureDate[1]
          ? searchItent?.departureDate[1].replace(/-/g, "")
          : ""
      }|dl3=${
        searchItent?.departureDate[2]
          ? searchItent?.departureDate[2].replace(/-/g, "")
          : ""
      }|dl4=${
        searchItent?.departureDate[3]
          ? searchItent?.departureDate[3].replace(/-/g, "")
          : ""
      }|dr1=${
        returnDatesList[0] ? returnDatesList[0].replace(/-/g, "") : ""
      }|dr2=${
        returnDatesList[1] ? returnDatesList[1].replace(/-/g, "") : ""
      }|dr3=${
        returnDatesList[2] ? returnDatesList[2].replace(/-/g, "") : ""
      }|dr4=${
        returnDatesList[3] ? returnDatesList[3].replace(/-/g, "") : ""
      }|rt=${returnDatesList.length > 0 ? "1" : "0"}|a=${
        numberOfPassengers.adults
      }|b=${numberOfPassengers.babies}|c=${
        numberOfPassengers.children
      }|et=${getCurrentTime()}|ip=|us=|if=${
        countryOrigin !== "BR" || countryDestination !== "BR" ? "1" : "0"
      }|co=${countryOrigin}|cd=${countryDestination}`,
      is_international_flight: 1,
      country_origin: countryOrigin,
      country_destination: countryDestination,
      ...transformDatesToGTM(departureDatesList, returnDatesList),
      trip_duration: calculateDateDifference(
        departureDatesList,
        returnDatesList
      ),
      is_multidates:
        departureDatesList.length > 1 || returnDatesList.length > 1,
      IATA_origin_autocomplete: originAutocomplete,
      IATA_destination_autocomplete: destinationAutocomplete,
    }
    return event
  }

  const openTabs = () => {
    const windows = []
    const baseURL = "http://localhost:3001"

    const urls = [
      `${baseURL}/passagens-aereas-de-sao-paulo/SAO/para-salvador/SSA?origin-airport=SAO&destination-airport=SSA&departure-date=2024-08-14,2024-08-15,2024-08-16,2024-08-17&return-date=2024-08-28,2024-08-29,2024-08-30,2024-08-31&quantity-of-adults=1&quantity-of-children=0&quantity-of-babies=0&total-expected-requests=6`,
      `${baseURL}/passagens-aereas-de-sao-paulo/SAO/para-fortaleza/FOR?origin-airport=SAO&destination-airport=FOR&departure-date=2024-08-14,2024-08-15,2024-08-16,2024-08-17&return-date=2024-08-28,2024-08-29,2024-08-30,2024-08-31&quantity-of-adults=1&quantity-of-children=0&quantity-of-babies=0&total-expected-requests=6`,
      `${baseURL}/passagens-aereas-de-sao-paulo/SAO/para-roma/FCO?origin-airport=SAO&destination-airport=FCO&departure-date=2024-08-14,2024-08-15,2024-08-16,2024-08-17&return-date=2024-08-28,2024-08-29,2024-08-30,2024-08-31&quantity-of-adults=1&quantity-of-children=0&quantity-of-babies=0&total-expected-requests=6`,
      `${baseURL}/passagens-aereas-de-sao-paulo/SAO/para-madri/MAD?origin-airport=SAO&destination-airport=MAD&departure-date=2024-08-14,2024-08-15,2024-08-16,2024-08-17&return-date=2024-08-28,2024-08-29,2024-08-30,2024-08-31&quantity-of-adults=1&quantity-of-children=0&quantity-of-babies=0&total-expected-requests=6`,
    ]

    for (let i = 0; i < 5; i++) {
      windows.push(window.open(urls[i], "_blank"))
    }

    windows.forEach((newWindow) => {
      if (newWindow) {
        newWindow.opener = null
      } else {
        console.error(
          "Não foi possível abrir a janela. Verifique se os pop-ups estão permitidos."
        )
      }
    })
  }

  const removeAccents = (str: string) => {
    return diacritics.remove(str)
  }

  const onSelectItemPlace = (itemPlace: frequentPlacesPros) => {
    const placeName = removeAccents(itemPlace.name)
      .toLowerCase()
      .replace(/ /g, "-")
    if (isOrigin) {
      setOriginAirport(itemPlace.iata)
      setOriginCity(placeName)
      setOriginLabel(itemPlace.description)
    } else {
      setDestinationAirport(itemPlace.iata)
      setDestinationCity(removeAccents(placeName))
      setDestinationLabel(itemPlace.description)
    }
    isMobile && handlerSearchModal(false)
    !isMobile && handlerShowFrequentPlacesWeb(false)
  }

  const getFrequentPlacesComponent = () => (
    <div className="div-frequent-places">
      {isBlurFrequentPlacesList && <div className="div-frequent-places-blur" />}

      <span className="title-frequent-places">
        {`${isOrigin ? "Origens mais buscadas" : "Destinos mais buscados"}`}
      </span>
      <div
        className="div-frequent-places-content"
        style={{
          overflowY: isBlurFrequentPlacesList ? "hidden" : "auto",
        }}
      >
        <div className="div-left-frequent-places-content">
          {isOrigin ? (
            <>
              {/* UNCOMMENT FOR APPLY TOP FREQUENT PLACES */}

              {topFrequentPlaces.topNationalOrigins.map(
                (item: frequentPlacesPros, index: number) => (
                  <span
                    onClick={() => {
                      onSelectItemPlace(item)
                    }}
                    className="text-frequent-places-top"
                  >{`${item.name} - (${item.iata})`}</span>
                )
              )}
              {nationalOriginsPlaces.map(
                (item: frequentPlacesPros, index: number) =>
                  !topFrequentPlaces.topNationalOrigins.some(
                    (itemPlace) => itemPlace.iata === item.iata
                  ) && (
                    <span
                      onClick={() => {
                        onSelectItemPlace(item)
                      }}
                      className="text-frequent-places"
                    >{`${item.name} - (${item.iata})`}</span>
                  )
              )}
            </>
          ) : (
            <>
              <div className="div-frequent-places-side-haeder">
                <div>
                  <Icon iconName="brazil-flag" width={24} iconColor="#000000" />
                </div>

                <span
                  className="text-requent-places-side-haeder"
                  style={{ marginTop: 3 }}
                >
                  Nacionais
                </span>
              </div>

              <>
                {topFrequentPlaces.topNationalDestiny.map(
                  (item: frequentPlacesPros, index: number) => (
                    <span
                      onClick={() => {
                        onSelectItemPlace(item)
                      }}
                      className="text-frequent-places-top"
                    >{`${item.name} - (${item.iata})`}</span>
                  )
                )}
                {nationalPlaces.map(
                  (item: frequentPlacesPros, index: number) =>
                    !topFrequentPlaces.topNationalDestiny.some(
                      (itemPlace) => itemPlace.iata === item.iata
                    ) && (
                      <span
                        onClick={() => {
                          onSelectItemPlace(item)
                        }}
                        className="text-frequent-places"
                      >{`${item.name} - (${item.iata})`}</span>
                    )
                )}
              </>
            </>
          )}
        </div>
        <div className="div-right-frequent-places-content">
          {!isOrigin && (
            <>
              <div className="div-frequent-places-side-haeder">
                <Icon
                  iconName="internetional-world"
                  width={20}
                  iconColor="#000000"
                />
                <span className="text-requent-places-side-haeder">
                  Internacionais
                </span>
              </div>

              {/* UNCOMMENT FOR APPLY TOP FREQUENT PLACES */}

              <>
                {topFrequentPlaces.topinternationalDestiny.map(
                  (item: frequentPlacesPros, index: number) => (
                    <span
                      onClick={() => {
                        onSelectItemPlace(item)
                      }}
                      className="text-frequent-places-top"
                    >{`${item.name} - (${item.iata})`}</span>
                  )
                )}
                {internationaPlaces.map(
                  (item: frequentPlacesPros, index: number) =>
                    !topFrequentPlaces.topinternationalDestiny.some(
                      (itemPlace) => itemPlace.iata === item.iata
                    ) && (
                      <span
                        id={`${index}-text-frequent-places`}
                        onClick={() => {
                          onSelectItemPlace(item)
                        }}
                        className="text-frequent-places"
                      >{`${item.name} - (${item.iata})`}</span>
                    )
                )}
              </>
            </>
          )}
        </div>
      </div>
    </div>
  )

  return (
    <>
      <Toast
        key={toastKey}
        isVisible={isShowToast}
        semanticType="error"
        onClose={() => handlerShowToast(false)}
        message={toastMessage}
        visibleTime={5}
      />
      <div className="div-content">
        <div className="div-title-content">
          <h1 className="title-content">
            {"COMPARE E ENCONTRE AS PASSAGENS AÉREAS MAIS BARATAS"}
          </h1>
        </div>
        <div className="div-inputs-search">
          <div className="div-inputs-places-quantify">
            <div className="div-inputs-places">
              {!isMobile && (
                <div className="div-inputs-search-switch-trip-type">
                  <div className="div-switch-trip">
                    <RadioGroup
                      options={["Ida e volta", "Só ida"]}
                      value={isOneWayTrip ? "Só ida" : "Ida e volta"}
                      onChange={(value) => {
                        dispatch(
                          setIsOnewayTrip({ isOnewayTrip: value === "Só ida" })
                        )

                        if (value === "Só ida") {
                          setReturnDateSelected([])
                          setReturnDateSelectedSaved(sortDatesArray([]))
                        }
                      }}
                    />
                  </div>
                </div>
              )}

              <>
                {!isMobile &&
                  isShowFrequentPlacesWeb &&
                  !isBlurFrequentPlacesList &&
                  isOrigin && (
                    <FloatContainer
                      height={500}
                      left={0}
                      onClickOut={() => handlerShowFrequentPlacesWeb(false)}
                    >
                      {getFrequentPlacesComponent()}
                    </FloatContainer>
                  )}

                <InputSearch
                  autoFocus={false}
                  isDesableSearchResults={isMobile}
                  label="De:"
                  inputIcon="plane-taking-off"
                  colorIcon="#00688D"
                  placeholder={isMobile ? "Escolha sua origem" : "Origem"}
                  onSelectedItem={(item: airportPros) => {
                    setOriginAirport(item.airport_code)
                    setOriginCity(formatStringToSlug(item.city_name))
                  }}
                  initialValue={searchItent?.originAirport}
                  onClear={() => {
                    setOriginAirport(null)
                    setOriginLabel("")
                  }}
                  isError={isOriginAirportError}
                  onClick={() => {
                    isMobile && handlerSearchModal(true)
                    !isMobile && handlerShowFrequentPlacesWeb(true)
                    setIsOrigin(true)
                  }}
                  isReadOnly={isMobile}
                  value={originLabel}
                  onSuggestions={(value) =>
                    handlerBlurFrequentPlacesList(value)
                  }
                  isDeparture
                  isSelected={isShowFrequentPlacesWeb && isOrigin}
                  handlerSearchModal={handlerSearchModal} // Passando a função para mobile
                  handlerShowFrequentPlacesWeb={handlerShowFrequentPlacesWeb} // Passando a função para web
                />
              </>

              <div className="div-inputs-places-separator" />

              <>
                {!isMobile &&
                  isShowFrequentPlacesWeb &&
                  !isBlurFrequentPlacesList &&
                  !isOrigin && (
                    <FloatContainer
                      height={500}
                      left={destinationInputWidth}
                      onClickOut={() => handlerShowFrequentPlacesWeb(false)}
                    >
                      {getFrequentPlacesComponent()}
                    </FloatContainer>
                  )}
                <InputSearch
                  autoFocus={false}
                  isDesableSearchResults={isMobile}
                  label="Para:"
                  inputIcon="plane-landing"
                  colorIcon="#00688D"
                  placeholder={isMobile ? "Escolha seu destino" : "Destino"}
                  onSelectedItem={(item: airportPros) => {
                    setDestinationAirport(item.airport_code)
                    setDestinationCity(formatStringToSlug(item.city_name))
                  }}
                  initialValue={searchItent?.destinationAirport}
                  onClear={() => {
                    setDestinationAirport(null)
                    setDestinationLabel("")
                  }}
                  isError={isDestinationAirportError}
                  onClick={() => {
                    isMobile && handlerSearchModal(true)
                    !isMobile && handlerShowFrequentPlacesWeb(true)
                    setIsOrigin(false)
                  }}
                  isReadOnly={isMobile}
                  value={destinationLabel}
                  onSuggestions={(value) =>
                    handlerBlurFrequentPlacesList(value)
                  }
                  onWidth={(valeu) => setDestinationInputWidth(valeu)}
                  isSelected={isShowFrequentPlacesWeb && !isOrigin}
                  handlerSearchModal={handlerSearchModal} // Passando a função para mobile
                  handlerShowFrequentPlacesWeb={handlerShowFrequentPlacesWeb} // Passando a função para web
                />
              </>
            </div>
          </div>

          <div className="div-inputs-date">
            {isShowSearchModal && (
              <div className={"div-background-footer-modal"}>
                <FooterModal
                  height={100}
                  iconName={isOrigin ? "plane-taking-off" : "plane-landing"}
                  titleIconColor={"#00688D"}
                  title={`Escolha ${isOrigin ? "sua origem" : "seu destino"}`}
                  onClose={() => {
                    handlerSearchModal(false)
                  }}
                >
                  <div className="div-search-footer-modal-place">
                    <div className="div-input-search-footer-modal-place">
                      <InputSearch
                        autoFocus
                        inputIcon="magnifier"
                        colorIcon="#666666"
                        label={isOrigin ? "De:" : "Para:"}
                        placeholder={`Buscar cidade ou aeroporto`}
                        onSuggestions={(value) =>
                          handlerBlurFrequentPlacesList(value)
                        }
                        onSelectedItem={(item: airportPros) => {
                          if (isOrigin) {
                            setOriginAirport(item.airport_code)
                            setOriginCity(item.city_name)
                            setOriginLabel(
                              `${item.city_name} - (${item.airport_code})`
                            )
                          } else {
                            setDestinationAirport(item.airport_code)
                            setDestinationCity(item.city_name)
                            setDestinationLabel(
                              `${item.city_name} - (${item.airport_code})`
                            )
                          }

                          handlerSearchModal(false)
                        }}
                        onClear={() => {
                          setOriginAirport(null)
                        }}
                        isError={isOriginAirportError}
                        onClick={() => isMobile && handlerSearchModal(true)}
                        isMobileModal={isMobile}
                        handlerSearchModal={handlerSearchModal} // Passando a função para mobile
                        handlerShowFrequentPlacesWeb={
                          handlerShowFrequentPlacesWeb
                        } // Passando a função para web
                      />
                    </div>
                    {getFrequentPlacesComponent()}
                  </div>
                </FooterModal>
              </div>
            )}

            <div
              className={
                isCalendarVisible && isMobile
                  ? "div-background-footer-modal"
                  : "hidden-calendar"
              }
            >
              <FooterModal
                height={100}
                iconName={"calendar"}
                title={
                  isDepartureDateSelected
                    ? "Selecionar datas de ida"
                    : "Selecionar datas de volta"
                }
                description={"Selecione até 4 opções de datas."}
                iconAboutDescription={
                  <Icon
                    iconName="interrogation"
                    width={20}
                    href="#getsitecontrol?id=373271"
                  />
                }
                onClose={() => {
                  setCalendarVisible(false)
                }}
              >
                <div style={{ width: "90%" }}>
                  <TripTypeSwitch
                    departureDates={departureDateSelectedSaved}
                    returnDates={returnDateSelectedSaved}
                    onClick={(isDeparture) => {
                      handlerDepartureDateSelected(isDeparture)
                    }}
                    isOneWayTripSelected={isDepartureDateSelected}
                  />
                  <CalendarPicker
                    isVisible={isCalendarVisible}
                    isMoboile={isMobile}
                    isDepartureCaledar={isDepartureDateSelected}
                    maxLimitSelected={4}
                    selectedDates={(dates: any) => {
                      if (isDepartureDateSelected) {
                        setDepartureDateSelected(sortDatesArray(dates))
                        setDepartureDateSelectedSaved(sortDatesArray(dates))
                      } else {
                        setReturnDateSelected(sortDatesArray(dates))
                        setReturnDateSelectedSaved(sortDatesArray(dates))
                      }
                    }}
                    departureSelectedDates={departureDateSelected}
                    returnSelectedDates={returnDateSelected}
                    onClose={() => {
                      if (isDepartureDateSelected) {
                        setDepartureDateSelected([])
                        setDepartureDateSelectedSaved(sortDatesArray([]))
                      } else {
                        setReturnDateSelected([])
                        setReturnDateSelectedSaved(sortDatesArray([]))
                      }
                    }}
                    onConfirm={() => {
                      if (isCalendarVisible) {
                        setCalendarVisible(false)
                      }
                    }}
                  />
                </div>
              </FooterModal>
            </div>

            <div className="div-switch-trip-passengers">
              {isMobile && (
                <div className="div-passengers">
                  <PassengersSelector
                    numberOfPassengers={numberOfPassengers}
                    onClick={() => handlerShowPassengersSelectorModal(true)}
                  />
                </div>
              )}

              {isMobile && (
                <div className="div-switch-trip">
                  <Switch
                    option1="Ida e volta"
                    option2="Só ida"
                    onChange={(value) => {
                      dispatch(
                        setIsOnewayTrip({ isOnewayTrip: value === "Só ida" })
                      )

                      if (value === "Só ida") {
                        setReturnDateSelected([])
                        setReturnDateSelectedSaved(sortDatesArray([]))
                      }
                    }}
                    defaultOption={"Ida e volta"}
                  />
                </div>
              )}

              {!isMobile && (
                <div className="div-trip-type-switch">
                  <TripTypeSwitch
                    isOnlyShow
                    isDisable={isOneWayTrip}
                    departureDates={departureDateSelectedSaved}
                    returnDates={returnDateSelectedSaved}
                    onClick={(isDeparture) => {
                      // handlerDepartureDateSelected(isDeparture);
                      onSelecterCalendar(true)
                      handlerDepartureDateSelected(isDeparture)
                    }}
                    isDepartureSelected={
                      isCalendarVisible && isDepartureDateSelected
                    }
                    isReturnSelected={
                      isCalendarVisible && !isDepartureDateSelected
                    }
                  />

                  <div
                    className={
                      isCalendarVisible && !isMobile
                        ? "div-background-footer-modal"
                        : "hidden-calendar"
                    }
                  >
                    <CalendarPicker
                      isVisible={isCalendarVisible}
                      isMoboile={isMobile}
                      isDepartureCaledar={isDepartureDateSelected}
                      maxLimitSelected={4}
                      selectedDates={(dates: any) => {
                        if (isDepartureDateSelected) {
                          setDepartureDateSelected(sortDatesArray(dates))
                          setDepartureDateSelectedSaved(sortDatesArray(dates))
                        } else {
                          setReturnDateSelected(sortDatesArray(dates))
                          setReturnDateSelectedSaved(sortDatesArray(dates))
                        }
                      }}
                      departureSelectedDates={departureDateSelected}
                      returnSelectedDates={returnDateSelected}
                      onClose={() => {
                        if (isDepartureDateSelected) {
                          setDepartureDateSelected([])
                          setDepartureDateSelectedSaved(sortDatesArray([]))
                        } else {
                          setReturnDateSelected([])
                          setReturnDateSelectedSaved(sortDatesArray([]))
                        }
                      }}
                      onConfirm={() => {
                        if (isCalendarVisible) {
                          setCalendarVisible(false)
                        }
                      }}
                      onClickOut={() => {
                        if (isCalendarVisible) {
                          !isMobile && setCalendarVisible(false)
                        }
                      }}
                    />
                  </div>
                </div>
              )}

              {!isMobile && (
                <div
                  className="div-passengers"
                  onMouseEnter={() => setIsHoveredDivPassengers(true)}
                  onMouseLeave={() => setIsHoveredDivPassengers(false)}
                  style={{
                    backgroundColor:
                      isHoveredDivPassengers && !isShowPassengersSelectorWeb
                        ? "#e0e0e0"
                        : isShowPassengersSelectorWeb
                        ? "#D2EFFF"
                        : "#FFFFFF",
                  }}
                >
                  <PassengersSelector
                    numberOfPassengers={numberOfPassengers}
                    onClick={() => handlerShowPassengersSelectorWeb(true)}
                  />

                  {isShowPassengersSelectorWeb && (
                    <FloatContainer
                      left={-290}
                      isSizePixel
                      width={350}
                      height={270}
                      onClickOut={() => handlerShowPassengersSelectorWeb(false)}
                    >
                      <div className="div-content-footer-passengers-selector-modal">
                        <div className="div-passengers-item-selector-modal">
                          <div className="div-label-passengers-item-selector-modal">
                            <span className="tille-passengers-item-selector-modal">
                              ADULTOS
                            </span>
                            <span className="description-passengers-item-selector-modal">
                              A partir de 16 anos.
                            </span>
                          </div>
                          <CountButton
                            oneChange={(value: number) => {
                              setAmountOfAdults(value)
                            }}
                            value={amountOfAdults}
                            minValue={1}
                          />
                        </div>

                        {/* Crianças e Bebês estão desabilitados temporariamente */}
                        {/* <div className="div-passengers-item-selector-modal">
                          <div className="div-label-passengers-item-selector-modal">
                            <span className="tille-passengers-item-selector-modal">
                              CRIANÇAS
                            </span>
                            <span className="description-passengers-item-selector-modal">
                              De 06 até 15 anos.
                            </span>
                          </div>
                          <CountButton
                            oneChange={(value: number) => {
                              setAmountOfChildren(value);
                            }}
                            value={amountOfChildren}
                            minValue={0}
                          />
                        </div>

                        <div className="div-passengers-item-selector-modal">
                          <div className="div-label-passengers-item-selector-modal">
                            <span className="tille-passengers-item-selector-modal">
                              BÊBES
                            </span>
                            <span className="description-passengers-item-selector-modal">
                              De 0 até 5 anos.
                            </span>
                          </div>
                          <CountButton
                            oneChange={(value: number) => {
                              setAmountOfBabies(value);
                            }}
                            value={amountOfBabies}
                            minValue={0}
                          />
                        </div> */}

                        <Button
                          title="Confirmar"
                          className="div-button-web"
                          textClassName="text-title-button-footer-modal"
                          // iconName={'FiSearch'}
                          onClick={() => {
                            dispatch(
                              setNumberOfPassengers({
                                numberOfPassengers: {
                                  adults: amountOfAdults,
                                  children: amountOfChildren,
                                  babies: amountOfBabies,
                                },
                              })
                            )

                            handlerShowPassengersSelectorWeb(false)
                          }}
                        />
                      </div>
                    </FloatContainer>
                  )}
                </div>
              )}
            </div>
          </div>

          <div className="div-inputs-date-type-trip">
            {isMobile && (
              <TripTypeSwitch
                isOnlyShow
                isDisable={isOneWayTrip}
                departureDates={departureDateSelectedSaved}
                returnDates={returnDateSelectedSaved}
                onClick={(isDeparture) => {
                  onSelecterCalendar(true)
                  handlerDepartureDateSelected(isDeparture)
                }}
              />
            )}

            {isShowPassengersSelectorModal && (
              <div className={"div-background-footer-modal"}>
                <FooterModal
                  height={100}
                  iconName={"profile"}
                  title={"Escolher Passageiros"}
                  description={"Selecione a quantidade de passageiros."}
                  onClose={() => {
                    handlerShowPassengersSelectorModal(false)
                  }}
                  footerElement={
                    <Button
                      title="Confirmar"
                      className="div-button-mobile"
                      textClassName="text-title-button-footer-modal"
                      // iconName={'FiSearch'}
                      onClick={() => {
                        dispatch(
                          setNumberOfPassengers({
                            numberOfPassengers: {
                              adults: amountOfAdults,
                              children: amountOfChildren,
                              babies: amountOfBabies,
                            },
                          })
                        )

                        handlerShowPassengersSelectorModal(false)
                      }}
                    />
                  }
                >
                  <div className="div-content-footer-passengers-selector-modal">
                    <div className="div-passengers-item-selector-modal">
                      <div className="div-label-passengers-item-selector-modal">
                        <span className="tille-passengers-item-selector-modal">
                          ADULTOS
                        </span>
                        <span className="description-passengers-item-selector-modal">
                          A partir de 16 anos.
                        </span>
                      </div>
                      <CountButton
                        oneChange={(value: number) => {
                          setAmountOfAdults(value)
                        }}
                        value={amountOfAdults}
                        minValue={1}
                      />
                    </div>

                    {/* <div className="div-passengers-item-selector-modal">
                      <div className="div-label-passengers-item-selector-modal">
                        <span className="tille-passengers-item-selector-modal">
                          CRIANÇAS
                        </span>
                        <span className="description-passengers-item-selector-modal">
                          De 06 até 15 anos.
                        </span>
                      </div>
                      <CountButton
                        oneChange={(value: number) => {
                          setAmountOfChildren(value)
                        }}
                        value={amountOfChildren}
                        minValue={0}
                      />
                    </div>

                    <div className="div-passengers-item-selector-modal">
                      <div className="div-label-passengers-item-selector-modal">
                        <span className="tille-passengers-item-selector-modal">
                          BÊBES
                        </span>
                        <span className="description-passengers-item-selector-modal">
                          De 0 até 5 anos.
                        </span>
                      </div>
                      <CountButton
                        oneChange={(value: number) => {
                          setAmountOfBabies(value)
                        }}
                        value={amountOfBabies}
                        minValue={0}
                      />
                    </div> */}
                  </div>
                </FooterModal>
              </div>
            )}
          </div>
          <div className="div-search-button">
            <Button
              title="BUSCAR PASSAGENS"
              iconName={"FiSearch"}
              textClassName="text-flights-serach-button"
              onClick={() => {
                onSubmitFlightsSearch()
              }}
            />
          </div>
        </div>

        <div className="div-banner-home-mobile-interface">
          <AdBanner
            adUnit={
              process.env.REACT_APP_IS_LOCAL
                ? "local-gpt"
                : "div-gpt-ad-1720783507953-0"
            }
            width={320}
            height={50}
            backgroundColor={
              process.env.REACT_APP_IS_LOCAL ? "#e90303" : "transparent"
            }
          />
        </div>
      </div>
    </>
  )
}

export default SearchFlights
